import React from "react"; // , { Component }
// import { Location } from '@reach/router';
import "typeface-poppins";
import "./src/css/index.css";

import ContactWidget from "./src/components/contactWidget";

// class SomeComponent extends Component {
//   render() {
//     const { location } = this.props;
//     return <div>{JSON.stringify(location)}</div>;
//   }
// }

export const wrapPageElement = ({ element, props }) => {
  const category = props?.pageResources?.json?.data?.strapiPage?.categoria?.Name ||
    props?.pageResources?.json?.data?.strapiProcedure?.parentCategory?.Name;

  return (
    <>
      {/* {JSON.stringify(props)} */}
      <ContactWidget category={category} />
      {/* <Location>
      {locationProps => <SomeComponent {...locationProps} />}
    </Location> */}
      {element}
    </>
  );
};
