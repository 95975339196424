// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-chirurgia-estetica-tsx": () => import("./../../../src/pages/blog/chirurgia-estetica.tsx" /* webpackChunkName: "component---src-pages-blog-chirurgia-estetica-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../../../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-cliniche-index-tsx": () => import("./../../../src/pages/cliniche/index.tsx" /* webpackChunkName: "component---src-pages-cliniche-index-tsx" */),
  "component---src-pages-cliniche-single-tsx": () => import("./../../../src/pages/cliniche/single.tsx" /* webpackChunkName: "component---src-pages-cliniche-single-tsx" */),
  "component---src-pages-codice-etico-tsx": () => import("./../../../src/pages/codice-etico.tsx" /* webpackChunkName: "component---src-pages-codice-etico-tsx" */),
  "component---src-pages-come-funziona-index-tsx": () => import("./../../../src/pages/come-funziona/index.tsx" /* webpackChunkName: "component---src-pages-come-funziona-index-tsx" */),
  "component---src-pages-come-funziona-single-tsx": () => import("./../../../src/pages/come-funziona/single.tsx" /* webpackChunkName: "component---src-pages-come-funziona-single-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-destinazioni-index-tsx": () => import("./../../../src/pages/destinazioni/index.tsx" /* webpackChunkName: "component---src-pages-destinazioni-index-tsx" */),
  "component---src-pages-destinazioni-single-tsx": () => import("./../../../src/pages/destinazioni/single.tsx" /* webpackChunkName: "component---src-pages-destinazioni-single-tsx" */),
  "component---src-pages-galleria-index-tsx": () => import("./../../../src/pages/galleria/index.tsx" /* webpackChunkName: "component---src-pages-galleria-index-tsx" */),
  "component---src-pages-grazie-newsletter-tsx": () => import("./../../../src/pages/grazie-newsletter.tsx" /* webpackChunkName: "component---src-pages-grazie-newsletter-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interventi-index-tsx": () => import("./../../../src/pages/interventi/index.tsx" /* webpackChunkName: "component---src-pages-interventi-index-tsx" */),
  "component---src-pages-interventi-single-tsx": () => import("./../../../src/pages/interventi/single.tsx" /* webpackChunkName: "component---src-pages-interventi-single-tsx" */),
  "component---src-pages-perche-all-estero-tsx": () => import("./../../../src/pages/perche-all-estero.tsx" /* webpackChunkName: "component---src-pages-perche-all-estero-tsx" */),
  "component---src-pages-post-index-tsx": () => import("./../../../src/pages/post/index.tsx" /* webpackChunkName: "component---src-pages-post-index-tsx" */),
  "component---src-pages-post-single-tsx": () => import("./../../../src/pages/post/single.tsx" /* webpackChunkName: "component---src-pages-post-single-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-specialisti-index-tsx": () => import("./../../../src/pages/specialisti/index.tsx" /* webpackChunkName: "component---src-pages-specialisti-index-tsx" */),
  "component---src-pages-specialisti-single-tsx": () => import("./../../../src/pages/specialisti/single.tsx" /* webpackChunkName: "component---src-pages-specialisti-single-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-turismo-medico-tsx": () => import("./../../../src/pages/turismo-medico.tsx" /* webpackChunkName: "component---src-pages-turismo-medico-tsx" */)
}

