import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FaAngleDown, FaTimes } from "react-icons/fa";

const ContactWidget = ({ category = null }) => {
  // 0: minimized, 1: semi-open, 2: fully open
  const [widgetState, setWidgetState] = useState(category ? 2 : 1);

  const getExpert = () => {
    return "Laura";
    // if (category === "Chirurgia Estetica") {
    //   return "Laura";
    // } else if (category === "Fertilità") {
    //   return "Stella";
    // } else {
    //   return null;
    // }
  };

  const mainWidgetTitle = () => {
    if (category === "Chirurgia Estetica") {
      return "Stai valutando un intervento di chirurgia estetica?";
    } else if (category === "Fertilità") {
      return "Stai provando ad avere un bambino?";
    } else if (category === "Trapianto Capelli") {
      return "Stai valutando un trapianto di capelli?";
    } else {
      return "Hai una domanda per noi?";
    }
  };

  const placeholderText = () => {
    const expert = getExpert();

    if (expert === "Laura") {
      return "Scrivici il tuo messaggio qui. La nostra esperta Laura sarà a tua disposizione per rispondere a tutte le tue domande e ti consiglierà per il meglio";
    } else {
      return "Scrivici il tuo messaggio qui. Le nostre esperte saranno a tua disposizione per rispondere a tutte le tue domande e ti consiglieranno per il meglio";
    }
  };

  if (widgetState == 0) {
    return (
      <div
        className={`${
          widgetState == 0 ? "auto" : "w-full lg:w-1/3"
        } text-sm fixed z-50 bottom-0 right-0 p-2 flex flex-col items-end`}
      >
        <div
          className="flex cursor-pointer p-3 bg-sterea-light-gray rounded-full filter drop-shadow-md"
          onClick={() => setWidgetState(2)}
        >
          <StaticImage
            quality={100}
            src="../images/laura_profile.png"
            placeholder="none"
            layout="constrained"
            width={48}
            className={`border-2 border-sterea-blue-purple rounded-full ${
              getExpert() === null && "mr-3"
            }`}
            alt="Foto di profilo di Laura Serzentaite, co-fondatrice di Sterea Medicina e Viaggi"
          />
        </div>
      </div>
    );
  } else if (widgetState == 1) {
    return (
      <div
        className={
          "w-full lg:w-1/3 text-sm fixed z-50 bottom-0 right-0 p-2 flex flex-col items-end"
        }
      >
        <div
          className="bg-sterea-light-gray text-sterea-blue rounded-full cursor-pointer text-xl p-3 mb-3 filter drop-shadow-md"
          onClick={() => setWidgetState(0)}
        >
          <FaTimes />
        </div>
        <div
          className="bg-sterea-light-gray text-sterea-blue rounded-lg p-6 text-center cursor-pointer filter drop-shadow-md"
          onClick={() => setWidgetState(2)}
        >
          <p className="font-bold">{mainWidgetTitle()}</p>
          <p className="-mt-3">
            Clicca qui per scriverci e ti risponderemo quanto prima
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`${
          widgetState == 0 ? "auto" : "w-full lg:w-1/3"
        } text-sm fixed z-50 bottom-0 right-0 p-2 flex flex-col items-end`}
      >
        <div className="w-full flex flex-col filter drop-shadow-md">
          <div className="bg-sterea-light-lily rounded-tl-lg rounded-tr-lg p-3 text-center">
            <div className="flex justify-end">
              <FaAngleDown
                className="cursor-pointer text-xl mr-3"
                onClick={() => setWidgetState(1)}
              />
              <FaTimes
                className="cursor-pointer text-xl"
                onClick={() => setWidgetState(0)}
              />
            </div>
            <p className="font-bold">{mainWidgetTitle()}</p>
            <p className="-mt-3">Richiedici una consulenza gratuita.</p>
          </div>
          <div className="bg-sterea-light-gray rounded-bl-lg rounded-br-lg p-6">
            <div className="w-full text-center mb-3">
              <StaticImage
                quality={100}
                src="../images/laura_profile.png"
                placeholder="none"
                layout="constrained"
                width={64}
                className={`${getExpert() === null && "mr-3"}`}
                alt="Foto di profilo di Laura Serzentaite, co-fondatrice di Sterea Medicina e Viaggi"
              />
            </div>
            <form
              name="contact"
              method="post"
              className="flex flex-col"
              data-netlify="true"
              action="/thank-you"
            >
              <input type="hidden" name="form-name" value="contact" />
              <textarea
                placeholder={placeholderText()}
                name="testo"
                className="inset-drop-shadow bg-white p-3 h-24 outline-none rounded mb-3"
              />
              <input
                type="email"
                placeholder="Email*"
                name="email"
                className="inset-drop-shadow bg-white p-3 outline-none rounded mb-3"
                required
              />
              <input
                type="text"
                placeholder="Nome"
                name="nome"
                className="inset-drop-shadow bg-white p-3 outline-none rounded mb-3"
              />
              <div className="flex justify-center">
                <input
                  type="submit"
                  value="Invia"
                  className="sterea-blue-purple-button w-full"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default ContactWidget;
